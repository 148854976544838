import React from "react";

export const NEWS2024 = {
  name: "2024",
  news: [
    {
      date: "Nov. 7, 2024",
      content: (
        <span>
          Jiaqing Chen and Tiankai Xie's paper, "Visualizing Loss Functions as Topological Landscape Profiles," has been accepted to NeurIPS 2024 Workshop on Symmetry and Geometry in Neural Representations (NeurReps). Congratulations Jiaqing and Tiankai!
        </span>
      ),
    },
    {
      date: "Nov. 7, 2024",
      content: (
        <span>
          Tiankai Xie and Jiaqing Chen's paper, "Evaluating Loss Landscapes from a Topology Perspective," has been accepted to NeurIPS 2024 Workshop on Scientific Methods for Understanding Deep Learning (SciForDL). Congratulations Tiankai and Jiaqing!
        </span>
      ),
    },
    {
      date: "Nov. 4, 2024",
      content: (
        <span>
          Jiaqing Chen successfully completed his PhD comprehensive examination. Congratulations!
        </span>
      ),
    },
    {
      date: "Oct. 15, 2024",
      content: (
        <span>
          Ross Maciejewski was inducted to the IEEE VGTC Visualization Academy.
        </span>
      ),
    },
    {
      date: "Sept. 12, 2024",
      content: (
        <span>
          Yixuan Wang's paper, "A Simulation-based Approach for Quantifying the Impact of Interactive Label Correction for Machine Learning," was accepted for publication in IEEE TVCG. Congrats Yixuan!
        </span>
      ),
    },
    {
        date: "Mar. 15, 2024",
        content: (
          <span>
            Jiayi Hong's paper "Carbon Emission in Football Games: Footprint Impact of Power Five Conference Realignment," has been accepted to Sustainability and Sports Science Journal. Congratulations Jiayi!
          </span>
        ),
    },
    {
      date: "Mar. 7, 2024",
      content: (
        <span>
          Rostyslav Hnatyshyn and Jiayi Hong's paper "Capturing Cancer as Music: Cancer Mechanisms Expressed through Musification," has been accepted to ACM CHI 2024. Congratulations Rosty and Jiayi!
        </span>
      ),
    },
    {
      date: "Mar. 7, 2024",
      content: (
        <span>
          Fan Lei and Arlen Fan's paper "Understanding Reader Takeaways in Thematic Maps Under Varying Text, Detail, and Spatial Autocorrelation," has been accepted to ACM CHI 2024. Congratulations Fan and Arlen!
        </span>
      ),
    },
    {
        date: "Jan. 12, 2024",
        content: (
          <span>
            Jiayi Hong and Rostyslav Hnatyshyn's paper "A Survey of Designs for Combined 2D+3D Visual Representations," has been accepted to PacificVis 2024. Congratulations Jiayi and Rosty!
          </span>
        ),
      }
  ],
};
